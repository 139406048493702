@import './variables.scss';

.about{
   
    .candidate-photo{
        float:left;
        vertical-align: top;
        height:100%;
        width:40%;
        margin:25px;
        max-width:350px;
        img{
            border-radius:10%;
            max-height:100%;
            width:100%;
            box-shadow:2px 2px 8px 2px rgba(255,255,255,0.2);

        }
        /* Media Queries */
        @media screen and (max-width: 600px) {
            width:80%;
            display:block;
            float:none;
            margin:15px 10%
        }

    }

    

}