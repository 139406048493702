@import './variables.scss';

.splash-outer{
    margin-top:100px;
    width:100%;
    background-color:$blue;
    min-height:800px;
    background-size:cover;
    background-position:top center;
    background-repeat:no-repeat;
    position:relative;
    text-align: center;

    .chevron-down{
        position:relative;
        top:55vh;
        background:none;
        border:none;
        cursor:pointer;
        animation: fade-slide-up 1s 1s ease-out forwards;
    

        .fa-chevron-down{
            opacity:0.8;
            color:$lightText;
            display:block;
            line-height:5px;
            width:100px;
            height:50px;
            margin-top:-25px;
            margin-bottom:-70px;
            animation: pulse 0.6s;
            animation-delay: 3s;
            animation-iteration-count: 4;
        }      

    }
}

@keyframes fade-slide-up {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
      opacity: 0.8;
      transform: none;
    }
  }

  @keyframes pulse {
    0% {
      opacity: .8;
      transform: none;
    }
    50% {
      opacity: .6;
      transform: scale(.8);
    }
    100% {
      opacity: 0.8;
      transform: none;
    }
  }