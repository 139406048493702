@import "./variables.scss";

.contact-us {
  .col {
    width: 50%;
    display: inline-block;
    vertical-align: middle;

    /* Media Queries */
    @media screen and (max-width: 740px) {
      width: 100%;
      display: block;
    }
  }

  .socials {
    .social-row {
      a {
        line-height: 45px;
        .social-image {
          vertical-align: middle;
          display: inline-block;
          width: 45px;
          height: 45px;
          padding: 15px;
        }
        span {
          display: inline-block;
          color: blue;
          text-decoration: underline;
        }
      }
    }

    /* Media Queries */
    @media screen and (max-width: 740px) {
      height: 50px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      background-color: $dGreen;
      display: flex;
      justify-content: space-between;
      box-shadow: 0 0 10px 1px $darkText;
      .social-row {
        text-align: center;
        width: 25%;
        height: 50px;
        display: inline-block;
        a {
          .social-image {
            vertical-align: middle;
            display: inline-block;
            width: 35px;
            height: 35px;
            padding: 10px;
          }
          span {
            display: none;
          }
        }
      }
    }
  }
}

.contact-form {

  p {
    @include strong-text;
    color: $darkText;
  }

  label {
    @include strong-text;
    color: $darkText;
    text-align: left;
    padding: 15px;
  }
  input {
    @include strong-text;
    font-family: "Josefin Slab";
    font-weight: 200;
    color: $darkText;
    background-color: $lightText;
    border: 2px solid darken($blue, 15%);
    border-radius: 4px;
    padding: 15px;
    width: 85%;
    display: block;
  }

  textarea {
    @include strong-text;
    font-weight: 200;
    color: $darkText;
    background-color: $lightText;
    border: 2px solid darken($blue, 15%);

    border-radius: 4px;
    padding: 15px;
    width: 85%;
    display: block;
  }
  .radio-button {
    display: inline-block;
    width: auto;
  }

  .submit-button {
    background-color: #1abc9c;
    color: #fff;
    text-decoration: none;
    font-family: Verdana, sans-serif;
    cursor:pointer;
    display: block;
    font-size: 2rem;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 8px;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    height: 40px;
    line-height: 40px;
    padding: 0 $defaultPaddingSmall;
    border: none;
    width: 100%;
    margin-top: 15px;
  }
}

.success-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1abc9c;
  opacity: 0.95;
  z-index: 2000;
  text-align: center;

  h1 {
    @include callout-text;
    color: $lightText;
    font-size: 3rem;
    font-weight: bold;
    margin-top: 30vh;
  }
  h2 {
    @include strong-text;
    color: $lightText;
    font-weight: bold;
    font-size: 2.5rem;
  }

  button {
    background-color: $lightText;
    color: $darkText;
    cursor: pointer;

    font-size: 2rem;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 8px;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    height: 40px;
    line-height: 40px;
    padding: 0 $defaultPaddingSmall;
    border: none;
    width: 50%;
    margin-top: 15px;
  }
}

.check-container {
  display: block;
  position: relative;
  padding:0 !important;
  margin-bottom: 12px;
  line-height:35px;
  width:100%;
  margin-left:30vw;

  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition:height 1s;

  h2 {
    padding-left: 50px;
  }

  @media screen and (max-width:1000px) {
   margin-left:5vw;
   h2 {
    font-size:2rem;

   }
}
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  background-color: #eee;
  border:2px solid $darkText;
  border-radius:4px;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 11px;
  top: -3px;
  width: 12px;
  height: 30px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.lawn-sign-details {    
    animation: slideDown;
    animation-duration:0.8s;
    animation-fill-mode:forwards;
    background-color:none;
    border:none
    label{
        display:flex;

        span{
            display:inline-block;
            width:30%;
            text-align:right;
            padding-right:25px;
            font-weight:bold;

            @media screen and (max-width:1000px) {
                display:block;
                width:100%;
            }
        }
        input,textarea {
            display:inline-block;
            width:60%;
            color:black !important;
            font-weight:bold;
            @media screen and (max-width:1000px) {
                display:block;
                width:100%;
            }
        }
    }

}

.lawnsign-img{
    position:relative;
    display:inline-block;
    height: 140px;
    width:15%;
    transform:rotate3d(10deg, 30rad,30rad, 10deg);
    margin:auto;
    @media screen and (max-width:1000px) {
        display:none;
    }
}
.lawnsign-title{
    display:inline-block;
    width:70%;
    line-height:15%;
    @media screen and (max-width:1000px) {
        display:block;
        width:100%;
        font-size:2rem !important;
        font-weight:bold;
        padding: 15px 0 !important;
        line-height:30px;
    }
}

@keyframes slideDown {
    0% {
        transform:scaleY(0);
        opacity: 0;
    }
    100% {
        transform:scaleY(1);
        opacity:1;
    }
}

