@import './variables.scss';

.section-title {
    @include callout-text;
    color:$darkText;
    font-size:2.5rem;
    padding:0px;
    margin:0px;
    text-align:center;
}


hr {
    width:50%;
    box-shadow:1px 1px 4px 2px rgba(255,255,255,0.2);
    margin:15px auto 30px auto;
}

.section{
    background-color:$dGreen;
    padding:$defaultPaddingLarge $defaultMargin;
    position:relative;
    vertical-align: top;

    .contents {
            margin-bottom:30px;
            left:40%;
            width:100%;
            display:block;
            color:$darkText;
            padding:15px;
            background-color:$lightText;
            font-weight:bold;
            border-radius:8px;
            border:1px solid darken($blue, 15%);
            box-shadow:2px 2px 8px 1px darken($blue, 15%);
            p{
                white-space: normal;
                @include strong-text;
            }
    
            a{
                color:$lightText;
                @include strong-text;
            }
        
    }
}

